import React, { useState, useEffect } from 'react';
import * as S from './style';
import * as T from '../../Typography';
import Icon from '../../Icon';
import { media } from '../../../api-calls';
import { format } from 'date-fns';

import { roles } from '../../../constants';
import FilesContainer from '../../FilesContainer';

const AnswerCard = ({
  name,
  content,
  circleColor,
  answerDate,
  role,
  answerId,
  ...props
}) => {
  useEffect(() => {
    if (answerId) {
      let mounted = true;
      async function getMediaById() {
        const { data: files } = await media.getMediaById({
          id: answerId,
        });
        if (files?.length > 0) {
          await Promise.all(
            files.map(async (file) => {
              const { data: url } = await media.getMediaURL({
                key: file.key,
                bucket: file.bucket,
                bucketRegion: file.bucketRegion,
              });
              if (mounted) {
                setFileList((prev) => [...prev, { name: file.name, url }]);
              }
            })
          );
        }
      }

      getMediaById();

      return () => {
        mounted = false;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerId]);
  const [fileList, setFileList] = useState([]);
  const firstName = name === 'Deleted User' ? name : name.split(' ')[0];

  return (
    <S.Wrapper {...props} column>
      <S.NoteHeader>
        <S.Circle circleColor={circleColor || 'purple'}>
          <Icon icon={'scouts'} width={18} height={18} color={'white'} />
        </S.Circle>
        <S.NoteTexts>
          <T.Body16B color={'purple'} display="block">
            {firstName},{' '}
            {role === roles.DIGITAL_CHAMPION ? 'Digital Champion' : 'HQ'}
          </T.Body16B>

          <T.BodySM color="gray" display="block">
            Answered {format(new Date(answerDate), 'dd MMM yyyy')}
          </T.BodySM>
        </S.NoteTexts>
      </S.NoteHeader>
      <S.ContentPart noMargin>
        <S.RichText mt={3} dangerouslySetInnerHTML={{ __html: content }} />
      </S.ContentPart>
      {fileList.length > 0 && (
        <FilesContainer filesList={fileList} showDeleteIcon={false} />
      )}
    </S.Wrapper>
  );
};

export default AnswerCard;
